import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from './SiteMetadata'
import { Layout } from 'antd';
import logoSrc from '../images/logo.svg';
import logoFavicon from '../images/logo-favicon.png';
import logoLarge from '../images/logo-large.png';
import { Link } from 'gatsby';
import './../styles/main.css';

const { Header, Content, Footer } = Layout;

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={logoFavicon}
        />
        <link
          rel="icon"
          type="image/png"
          href={logoFavicon}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={logoFavicon}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={logoFavicon}
          color="#ff4400"
        />
        <link rel="canonical" href={'https://codepanion.com'} />
        <meta name="theme-color" content="#fff" />

        <meta property="og:locale" content="en" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://codepanion.com" />
        <meta property="og:site_name" content={title} />
        <meta
          property="og:image"
          content={logoLarge}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={logoLarge} />
      </Helmet>

      <Layout>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Link to="/" style={{
            fontWeight: 'bold',
            fontSize: '18px',
            color: 'rgb(39, 100, 102)'
          }}>
            <img className="logo" src={logoSrc} style={{ maxWidth: 40, marginRight: 10 }} />
            CODEPANION <span style={{color: '#fff'}}>BLOG</span>
          </Link>
        </Header>

        <Content style={{ marginTop: 64 }}>
          <div style={{ marginTop: 20, padding: 24, minHeight: 380 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', fontSize: '14px' }}>
          ©{new Date().getFullYear()} Stefan Mitrović
        </Footer>
      </Layout>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
